@import '../assets/stylesheets/variable';
//header file

.list-mb {
  @media (min-width: 800px) {
    display: none;
  }
}

.header{
  height: 185px;

  @media (max-width: 800px){
    height: 30px;
  }

  .school-header{
    height: 130px;
    background-image: url("../assets/images/bhu1.jpeg");
    background-size: cover;
    background-repeat: no-repeat;

    .school-name{
      margin-top: 25px;

      @media (max-width: 900px){
        font-size: 15px;
        margin-top: 8px;
      }
    }

    .drukschool-logo{
      width: 120px;
      height: 120px;

      @media (max-width: 900px) {
        width: 60px;
        height: 60px;
      }
    }
    .school-details{
      @media (max-width: 800px){
        display: none;
      }
    }
  }

  .menu-button{
    @media (min-width: 800px){
      display: none;
    }
  }
}

.btn {
  height: 55px;
  margin-right: 30px;

  .nav-list {
    text-transform: uppercase;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    color: $white-color;

    &:hover {
      text-decoration: underline;
    }
  }
}

.sign-in{
  text-transform: capitalize;
  font-size: 18px;
  margin-top: 3px;

  @media (max-width: 800px){
    margin-left: 0;
    display: none;
  }
}

.collapse-mb {
  @media (max-width: 800px){
    display: none;
  }
}

.nav-header-list {
  .header-list {
    @media (max-width: 800px) {
      display: none;
    }
  }
}

