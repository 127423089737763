
.user-info-form {
  margin-left: 15px !important;

  @media only screen and (max-width: 600px) {
    margin: 10px !important;
  }
}

.button-spacing {
  @media only screen and (max-width: 600px) {
    margin: 10px !important;
  }
}