
.nav-background {
  background-color: white;
}

.nav-hover {
  cursor: pointer;
  position: relative;
  padding: 10px 20px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: all 1s;
  color: #546e7a !important;

  &:hover {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    background: #f5f5f5 !important;
    color: white !important;
    font-weight: bold !important;
  }
}

.data-container {
  background: #ffebee;
  display: flex;
  justify-content: center;
  align-items: center;

  .nav-ico {
    color: #546e7a !important;
  }
}

.nav-content {
  color: black !important;
  height: 100% !important;
}

.content {
  background-color: #ebebeb !important;
  background-repeat: no-repeat;
  opacity: 0.7;
  background-size: cover;
}

.log-out {
  position: relative !important;
  bottom: 5px !important;
  margin-top: 150px !important;
  margin-left: 3px !important;
  width: 150px !important;
  color: white !important;
  text-align: center !important;
  border-radius: 25px !important;
}