/* Default Variables */
$primary-color: #1565C0 !default;
$radius: 40px !default;

/* Fixed Variables */
$white-color: #FFFFFF;
$gallery-color: #EEEEEE;
$black-color: #000;
$boulder-gray: #747474;
$dove-gray: #636262;
$tundora-color: #444444;
$athens-gray: #F2F4F7;
$card-gray: #F2F2F2;
$albaster-gray: #FAFAFA;
$success-color: #11AA44 !default;
$error-color: #d32f2f !default;
$warning-color: #FF6600 !default;
$yellow: #FFC008 !default;
$info-color: #64B9E6;
$gradient: linear-gradient(0deg, $athens-gray 0%, $white-color 100%);
$primary-color: #4050b5;
$form-background : #ffffff8a;
$box-shadow : 2px 2px 5px rgba(0, 0, 0, 0.2);;
$web-kit-shadow : 2px 2px 5px rgba(0, 0, 0, 0.2);
$font-family: 'fantasy';
$blue-color: #013A6B;

$light-bg: rgba(239, 250, 251, 0.57);
$apple: #46CE2C;
$azure-radiance: #1477F0;
$ecstasy: #F88620;
$light-bg: rgba(239, 250, 251, 0.57);
