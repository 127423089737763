
.assignment-card {
  width: 95%;
  margin-left: 10px;

  .card-head {
    background-color: #b8b8b8;
    color: white;
    height: 100px;
  }
}