
.assignment-card {
  width: 95%;
  margin-left: 10px;

  .card-head{
    background-color: yellow;
    height: 100px;
  }
  .card-content{
  }
}