
.card-color {
  border-radius: 0 !important;
  position: fixed;
  width: 100% !important;
  z-index: 1;

  .header-background {
    background-color: white;
  }
}