@import 'variable';

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.hand-cursor {
  cursor: pointer;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.clear-fix {
  clear: both;
}

.no-wrap {
  white-space: nowrap;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.list-style-none {
  list-style: none;
}

[hidden] {
  display: none !important;
}

.unset-overflow {
  overflow: unset !important;
}

.unset-height {
  height: unset !important;
}

.unset-width {
  width: unset !important;
}

a {
  color: $primary-color;

  &:hover, &:focus {
    cursor: pointer;
  }
}

.avatar-xl {
  width: 200px;
  height: 200px;
  border-radius: 50px;
}

.avatar-lg {
  width: 160px;
  height: 160px;
  border-radius: 50px;
}

.avatar-md {
  width: 120px;
  height: 120px;
  border-radius: 50px;
}

.avatar-sm {
  width: 80px;
  height: 80px;
  border-radius: 50px;
}

.avatar-xs {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.gray-color {
  color: rgba(0, 0, 0, 0.6);
}

.green-yellow {
  color: #95F53D;
}

.green-color {
  color: #11AA44;
}

.orange-color {
  color: #FF6600;
}

.yellow-color {
  color: #FFC008;
}

.white-color {
  color: #FFFFFF;
}

.text-bold {
  font-weight: bold;
}

.primary-color{
  color: #4050b5;
}
.light-green {
  color: #64B9E6;
}

.decoration-none {
  text-decoration: none;
}

.outline-btn {
  border: 1px solid $primary-color !important;
  background: transparent !important;
  color: $primary-color !important;
}


