
.overview {
  &:hover{
    text-decoration: underline;
    color: yellow;
  }
}

.overview-types{
  margin-top: 170px;
  padding: 20px;

  @media (max-width: 800px){
    margin-top: 25px;
  }
  .anthem-title{
    text-decoration: underline;
  }
}

.overview-board{
  margin-top: 170px;
  padding: 100px;

  @media (max-width: 800px){
    margin-top: 40px;
    padding: 20px;
  }
  .anthem-title{
    text-decoration: underline;
  }

  .board-head{
    text-decoration: underline;
  }

  .board{
    text-decoration: underline;
    text-align: left;
  }
}