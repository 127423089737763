@import '../../../assets/stylesheets/variable';


.image-carousel {
  margin-top: 225px;
  margin-bottom: 35px;

  .img {
    width: 95%;
    height: 300px;

    @media (max-width: 800px) {
      height: 180px;
    }
  }

  @media (max-width: 800px) {
    margin-top: 70px;
    margin-bottom: 15px;
  }
}

