/*

.assignment-card {
  width: 95%;
  margin-left: 10px;

  .card-head{
  background-color: yellow;
  }
  .card-content{
  }
}*/


//class view

.card-class{
  width: 90%;
  height: 100px;

  @media (max-width: 800px){
    width: 100%;
  }

  .card-content {
    background-image: linear-gradient(green 30%, blue 70%);
  }
}