@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

html,body {
  height: 100%;
}

.App {
  text-align: center;
  height: inherit;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.dropZone {
    min-height: 112px !important;
    margin: 20px 0;
}
.dropZoneDes {
    font-size: 14px !important;
}

.MuiChip-root-498 {
    width: 100%;
    justify-content: space-between !important;
    margin: 4px 0;
}

.py-24 {
    padding: 24px 0;
}


/*CSS fro calendar*/
.month_default_event_inner {
    background: #999999;
    border-color: #777777;
    color: #fff;
    font-size: 12px;
    padding: 0px 10px;
    opacity: 0.8;
    border-radius: 4px;

    /* vertical centering */
    display: flex;
    align-items: center;
}

/* context menu icons */
.icon:before {
    position: absolute;
    left: 0px;
    margin-left: 8px;
    margin-top: 3px;
    width: 14px;
    height: 14px;
    content: '';
}

.icon-blue:before { background-color: #3c78d8; }
.icon-green:before { background-color: #6aa84f; }
.icon-yellow:before { background-color: #e69138; }
.icon-red:before { background-color: #cc0000; }

@font-face {
    font-family: 'daypiloticons';
    src:url('icons/fonts/daypiloticons.eot?cyz0y3');
    src:url('icons/fonts/daypiloticons.eot?cyz0y3#iefix') format('embedded-opentype'),
    url('icons/fonts/daypiloticons.ttf?cyz0y3') format('truetype'),
    url('icons/fonts/daypiloticons.woff?cyz0y3') format('woff'),
    url('icons/fonts/daypiloticons.svg?cyz0y3#daypiloticons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'daypiloticons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-edit:before {
    content: "\e908";
}
.icon-triangle-down:before {
    content: "\e907";
}
.icon-info:before {
    content: "\e905";
}
.icon-move:before {
    content: "\e900";
}
.icon-menu:before {
    content: "\e903";
}
.icon-bubble:before {
    content: "\e904";
}
.icon-resize-left:before {
    content: "\e901";
}
.icon-resize-right:before {
    content: "\e902";
}
.icon-menu2:before {
    content: "\e906";
}

