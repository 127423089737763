@import '../../assets/stylesheets/variable';
.preloader-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  background: $light-bg;
  width: 100%;
  height: 100%;

  .preloader {
    left: 50%;
    top: 45%;
    color: crimson;
    position: absolute;
  }

  .offline-text {
    left: 40%;
    top: 40%;
    position: absolute;
  }

  @media (max-width: 600px) {
    .offline-text {
      left: 10%;
      top: 40%;
      position: absolute;
    }
  }
}